<template>
    <div class="container roman-headers">
      <div class="data-form col-md-8 col-sm-8">
        <div class="tile">
          <div>
              <div v-if="alert.msg" :class="alert.class" role="alert">
                {{alert.msg}}
              </div>
              <div v-if="loading">
                <Loader/>              
              </div>
              <p class="text-center mt-20 mb-20">{{ $t('forgot_password.title') }}</p>
              <form novalidate>
                <div class="form-group" :class="{ 'error': v$.email.$error}">
                    <div class="display-flex data-error"><span class="font-normal-12 ml-4">{{ $t('forgot_password.form.username') }}:</span></div>
                    <input type="text" class="form-control font-normal-12" v-model="email" :placeholder="$t('forgot_password.form.username')" @blur="v$.email.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.email.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('forgot_password.form.error.username') }}</span></div>
                    <div class="data-error font-normal-12" v-if="!v$.email.required.$invalid && v$.email.$error"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('forgot_password.form.error.email_inv') }}</span></div>
                </div>             

                <div class="form-group float-right mt-20">
                    <input type="button" v-bind:value="$t('forgot_password.form.button')" class="btn btn-primary btn-orange font-normal-12 create-btn mb-20" v-on:click="validateForm">
                </div>
            </form>
          </div>
          </div>
      </div>
    </div>   
</template>

<script>
import AuthService from "../auth/service/AuthService";
import AppConstant from "../../config/app-constant";
import Loader from "@/components/Loader.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { isEmailValid } from "./validators.js";

export default {
  name: 'ForgotPassword',
  components: {
    Loader
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true }) };
  },
  data () {
    return {
      lang: 'en',
      email: '',
      formSubmitted: false,
      loading: false,
      alert: {

      },
      error: {        
        invalid_email: ''
      },
    }
  },
  validations: {
    email: {
      required,
      emailCheck: function emailValidate() {
        if (this.email.includes('@')) {
          return isEmailValid(this.email);
        } else {
          return true;
        }        
      }
    },
  },
  mounted () {
    document.body.classList.add('green-bg-img');
    document.getElementById('header-nav').classList.remove('nav-img');
  },
  destroyed () {
    document.body.classList.remove('green-bg-img');
    document.getElementById('header-nav').classList.add('nav-img');
  },
  created() {
    this.lang = this.$route.params.lang ? this.$route.params.lang : 'en';
  },
  methods:{
    validateForm: function () {
      window.scrollTo(0,0);
      this.formSubmitted = true;
      this.error.invalid_email = false;
      this.alert = {};
      this.v$.$touch()
      if (this.v$.$invalid) {
         return;
      } else {
        this.forgotPassword();
      }
    },
    hideErrorMsg: function (homeRedirect){
      setTimeout(()=>{
          this.$set(this.alert, 'class', '');
          this.$set(this.alert, 'msg', '');
          if (homeRedirect) {
            this.$router.push({ name: 'Home', params: { lang: this.$route.params.lang }  });
          }
      },3000);
    },
    forgotPassword: async function () {
      this.loading = true;
      const url = `${window.location.origin}/${this.lang}`;
      const data = await AuthService.forgotPassword(this.email, url);
      this.loading = false; 
      if (data.accepted && data.accepted.length > 0) {
        this.$set(this.alert, 'class', AppConstant.ALERT_SUCCESS_CLASS);
        this.$set(this.alert, 'msg', AppConstant[`FORGOT_PASSWORD_SUCCESS_MSG_${this.lang}`]);
        this.formSubmitted = false;
        this.hideErrorMsg(true);
      } else {
        if (data.error && !data.success) {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', AppConstant[`UNKOWN_RESET_USER_MSG_${this.lang}`]);
          this.hideErrorMsg();
        } else {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', AppConstant[`SOMETHING_WENT_WRONG_MSG_${this.lang}`]);
          this.hideErrorMsg();
        }      
      }
    },
    fetchGroupsAndOrganizationList: async function (){
      const data = await AuthService.getGroupsAndOrganizationList();
      this.organizationList = data.organization_list;
      this.groupList = data.group_list;
    }
  }
}
</script>

<style>
.green-bg-img {
  background-image: url("../../../public/img/green_background_full.png");
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 1900px) {
  .login {
    background-size: 100% 35%;
  }
}

.data-form {
  float: none;
  margin: auto;
  color: var(--grayfontlight);
}

.green-bg-img .tile {
  width: 100%;
  height: auto;
  margin-top: 64px;
  box-shadow: 5px 5px 5px rgb(0 0 0 /10%);
  border-radius: 6px;
}

.tile > div {
  padding: 24px 88px;
}

.form-group {
  margin-bottom: 18px;
}

.green-bg-img .form-group .form-control {
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 4px rgb(0 0 0 /5%);
}

.create-btn {
  border-radius: 4px;
  width: 124px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

@media (max-width: 768px) {
  .tile > div {
    padding: 24px 48px;
  }
}
</style>
