<template>
  <div id="datalist" class="container">
    <div class="row">
      <section class="col-md-1"></section>
      <section class="col-md-3 col-sm-4 col-xs-4">
        <!-- TODO: Categories -->
        <section class="section-title">{{ $t('data.categories') }}</section>
        <categories :category-list="categoryList" @data-search="dataSearch"/>
      </section>

      <section class="col-md-7 col-sm-8 col-xs-8">
        <h2>Show cases</h2>

        <div class="showcase-cards">
          <div :key="showcase.id" v-for="showcase in datalist">
            <ShowcaseTile :showcase="showcase" />
          </div>
          <div v-if="datalist.length == 0 && !loading" class="display-flex d-flex-justify-center mt-50 text-center"><h1 class="header-text data-info">{{ $t('data.no_data') }}</h1></div>
          <div v-if="loading">
            <Loader/>              
          </div>         
        </div>
      </section>
      <section class="col-md-1"></section>
    </div>

    <!-- TODO: Pagination -->
    <nav aria-label="Datasets" class="text-center">
        <Paginate
          v-model="page"
          :page-count="totalPages"
          :click-handler="getPage"
          :prev-text="'&laquo'"
          :next-text="'&raquo;'"
          :container-class="'pagination'">
        </Paginate>
      </nav> 
  </div>
</template>

<script>
import Categories from '../components/Categories.vue'
import ShowcaseTile from '../components/ShowcaseTile.vue'
import DataService from './service/DataService'
import Loader from '@/components/Loader.vue'
import Paginate from 'vuejs-paginate'

export default {
  components: { Categories, Loader, Paginate, ShowcaseTile },
  name: 'Showcases',
  data() {
      return {
          datalist: [],
          categoryList: [],
          fileFormatList: [],
          page: 1,
          tag: {},
          totalPages: 0,
          loading: true,
          globalQuery:''
      }
    },
  methods: {
    async fetchData() {
      this.loading = true;
      let searchUrl = `/api/showcase_list?q="${this.globalQuery}"&fq=dataset_type:showcase`;
      if (Object.keys(this.tag).length > 0) {
        searchUrl = searchUrl + ` AND tags:"`;
        const query = Object.keys(this.tag).join(`" AND tags: "`);
        searchUrl = searchUrl + query + `"`;
      }
      let page = (this.page - 1) * 2;
      searchUrl = searchUrl + `&facet.limit=100&size=2&from=${page}`;
      const data = await DataService.fetchData(searchUrl);
      this.loading = false;
      this.datalist = data.results;
      this.totalPages = data.pagination.totalPages;
      this.categoryList = [];
      for (let key in data.facets.tags) {
        const category = {
          name: key,
          count: data.facets.tags[key],
          selected: Object.keys(this.tag).length > 0 && this.tag[key] ? true: false
        }                    
        this.categoryList.push(category);
      }
      this.categoryList.sort(x => x.selected ? -1 : 1);
    },
    getPage: function(pageNum) {
      this.page = pageNum;
      this.$router.push( { name: 'Showcases', params: { lang: this.$route.params.lang, id:this.page }, query: this.$route.query });
    },
    async dataSearch(tag) {
        var categories = this.categoryList.filter((category) => category.name === tag);
        const queryParam = Object.assign({}, this.$route.query);
        for (const category of categories) {
            if (category.selected == false) {
              this.tag[tag] = tag;                  
            } else {
              delete this.tag[tag];
            }
        }
        if (Object.keys(this.tag).length === 0) {
            delete queryParam.tags;
        } else {
          const tags = Object.keys(this.tag).join(",");
          queryParam.tags = tags;
        }
        this.page = 1;    
        this.$router.push( { name: 'Showcases', params: { lang: this.$route.params.lang, id:this.page }, query: queryParam });
    },
    checkQueryParam: function() {
      if (this.$route.query && this.$route.query.search) {
        this.globalQuery = this.$route.query.search;
      } else {
        this.globalQuery = '';
      }
      if (this.$route.query && this.$route.query.tags) {
        const tags = this.$route.query.tags.split(",");
        for (const tag of tags) {
          this.tag[tag] = tag;
        }         
        this.updateTagClass(true);
      } else {
        this.tag = {};
        this.updateTagClass(false);
      }
      this.fetchData();
    },
    updateTagClass: function(selected) {
      for (let tag of this.categoryList) {
        if (this.tag[tag.name]) {
          tag.selected = selected; 
        } else {
          tag.selected = false;
        }                        
      }               
    },
    routeChange: function(){
      this.page = this.$route.params.id;
      this.checkQueryParam();
    }
  },  
  async created() {
    this.routeChange();
  },
  watch: {
      $route() {
        this.routeChange();
      }
  }
}
</script>