<template>
   <div class="container custom-container roman-headers">
    <h1 class="text-center mb-32">Impressum</h1>
    <div class="gray-text-container">
        <div class="row">
            <div class="col-md-12 font-16 font-frutiger">
                <div>
                    <p>{{ $t("impressum.address.name") }}</p>
                    <p class="mt-24">Campusallee 1</p>
                    <p>32657 Lemgo</p>
                    <p class="mt-24">{{ $t("impressum.address.phone") }} +49 5261 942 90 22</p>
                    <p>Fax +49 5261 942 90 90</p>
                    <p>Email sek-ina@iosb.fraunhofer.de</p>
                    <p class="mt-24">{{ $t("impressum.address.p_1") }}</p>
                    <p class="mt-24">{{ $t("impressum.address.p_2") }}</p>
                    <p>Phone +49 89 1205-0</p>
                    <p>Fax +49 89 1205-7531</p>
                    <p>Email: info(at)zv.fraunhofer.de</p>
                    <a target="_blank" class="text-underline" href="https://www.fraunhofer.de/">www.fraunhofer.de</a>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.tax.info") }}</p>
                    <p>DE129515865</p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.registry.name") }}</p>
                    <p>{{ $t("impressum.registry.p_1") }}</p>
                    <p>{{ $t("impressum.registry.p_2") }}</p>
                    <p>{{ $t("impressum.registry.p_3") }}</p>
                </div>                
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_4.name") }}</p>
                    <p>{{ $t("impressum.sec_4.p_1") }}</p>
                    <p>{{ $t("impressum.sec_4.p_2") }}</p>
                    <p>Fraunhofer IOSB-INA</p>
                    <p><span>Email</span>  <span class="text-underline">mischa.gutknecht-stoehr@iosb-ina.fraunhofer.de</span></p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_5.name") }}</p>
                    <p>{{ $t("impressum.sec_5.p_1") }}</p>
                    <p>{{ $t("impressum.sec_5.p_2") }}</p>
                    <p>{{ $t("impressum.sec_5.p_3") }}</p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_6.name") }}</p>
                    <p>Copyright © by</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_1") }}</p>
                    <p>{{ $t("impressum.sec_6.p_2") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_3") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_4") }}</p>
                    <p>{{ $t("impressum.sec_6.p_5") }}</p>
                    <p class="mt-16">Fraunhoferstrasse 1</p>
                    <p>76131 Karlsruhe</p>
                    <p class="mt-16">{{ $t("impressum.address.phone") }} +49 721 6091-0</p>
                    <p>Fax +49 721 6091-413</p>
                    <p>Email info@iosb.fraunhofer.de</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_6") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_7") }}</p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_7.name") }}</p>
                    <p>{{ $t("impressum.sec_7.p_1") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_7.p_2") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_7.p_3") }}</p>                    
                    <p class="mt-16">{{ $t("impressum.tax.info") }}</p>
                    <p>sales tax law: DE 129515865</p>
                    <p class="mt-16">{{ $t("impressum.sec_7.p_4") }}</p>
                    <p>{{ $t("impressum.sec_7.p_5") }}</p>
                    <p>{{ $t("impressum.registry.p_2") }}</p>
                    <p>{{ $t("impressum.registry.p_3") }}</p>
                </div> 
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_8.name") }}</p>
                    <p>{{ $t("impressum.sec_8.p_1") }}</p>
                    <p>{{ $t("impressum.sec_8.p_2") }}</p>
                    <p>{{ $t("impressum.sec_8.p_3") }}</p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_6.name") }}</p>
                    <p>© Fraunhofer-Gesellschaft eV, Munich</p>
                    <p class="mt-16">{{ $t("impressum.sec_6.p_1") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_9.p_1") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_9.p_2") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_9.p_3") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_9.p_4") }}</p>
                </div>
                <div class="mt-24">
                    <p class="font-24">{{ $t("impressum.sec_7.name") }}</p>
                    <p>{{ $t("impressum.sec_10.p_1") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_10.p_2") }}</p>
                    <p class="mt-16">{{ $t("impressum.sec_10.p_3") }}</p>
                </div>           
                    <a target="_blank" class="text-underline" href="https://www.fraunhofer.de/">www.fraunhofer.de</a>
                </div>                         
            </div>
        </div>
    </div>     
</template>

<script>
export default {
  name: 'Impressum'
}
</script>


