<template>
  <div class="in-progress-container p-56">
    <div class="pl-48">
      <div>
        <a class="logo pull-left" href="/">
          <img class="img-responsive" src="/img/Logo_KI_Reallabor_NEU.svg" />
        </a>
      </div>
      <div class="in-progress-msg-container display-flex d-flex-column pl-102 pt-72">
        <div class="header-msg">This site is still in progress.</div>
        <div class="w-50">
          This data portal is still band new. This page has not made it yet. It
          won't be long before you can find content on this Page as well. Until
          then, click through the other existing pages.
        </div>
        <div class="w-50 mt-20 display-flex d-flex-column">
          <span>We are looking forward to present you the finished site soon.</span>
          <span>If you have any questions until then, please contact us at
            info@ki-reallabor.de.</span>
        </div>
        <div class="w-50 mt-20">
          <div class="display-flex d-flex-justify-between w-75">
            <span>STATUS PROGRESS</span><span>90%</span>
          </div>
          <div class="progress w-75">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 90%"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="mt-32">
              <router-link to="/" class="btn-primary btn-orange login-btn nav-btn back-homepage-btn">Back to homepage</router-link>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>

export default {
  name: 'InProgress',
  mounted () {
    document.body.classList.add('nav-img');
  },
  destroyed () {
    document.body.classList.remove('nav-img');
  }
}

</script>