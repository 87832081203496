<template>
    <div class="container custom-container roman-headers">
        <h1 class="text-center mb-32">{{ $t("about_us.title") }}</h1>
        <h3 class="text-center">{{ $t("about_us.desc") }}</h3>
        <hr/>
        <div class="gray-text-container">

            <h3>{{ $t("about_us.h") }} </h3>

            <p>{{ $t("about_us.p_1") }}</p>

            <p>{{ $t("about_us.p_2") }} </p>

            <p>{{ $t("about_us.p_3") }}</p>

            <p>{{ $t("about_us.p_4") }}</p>

            <img class="w-100" src="/img/Projektstruktur_KIReallabor.png"/>

        </div>
    </div>    
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>


