<template>
   <div class="container custom-container roman-headers">
    <h1 class="text-center mb-32">Terms of Use</h1>
    <h3 class="text-center">Depending on the CC licenses of the datasets, the terms of use differ.</h3>
    <hr/>
    <div class="gray-text-container">
        <div class="row font-16 font-frutiger">
            <div class="col-md-12">
                <h2 class="text-center">Meaning of the CC license</h2>
                <div>Every dataset in this data portal has a CC license. You can find out what the respective CC licenses with their symbols mean here:</div>
                <div>
                    <div class="display-flex mt-32">
                        <span class="svg-container">
                            <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><path class="cls-2" d="M47.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/><path class="cls-2" d="M68.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><ellipse class="cls-1" cx="32.5" cy="31.48" rx="11" ry="14.5"/><line class="cls-1" x1="41.5" y1="22.48" x2="23.5" y2="41.48"/></svg>
                        </span>
                    </div>                    
                    <div>Free Use</div>
                    <ul class="mt-32">
                        <li>
                            You may use this dataset for non-commercial and commercial purposes.
                        </li>
                        <li>
                            A source citation is recommended (author, title and link to the dataset).
                        </li>    
                    </ul>
                    <div class="display-flex mt-32">
                        <span class="svg-container">
                            <svg id="Ebene_3" data-name="Ebene 3" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><path class="cls-2" d="M47.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/><path class="cls-2" d="M68.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_4" data-name="Ebene 4" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><circle class="cls-2 cls-3" cx="32.5" cy="17" r="5"/><path class="cls-2 cls-3" d="M60,43.68a3.18,3.18,0,0,0-3.18-3.18H43.18A3.18,3.18,0,0,0,40,43.68V55.5h4v15H56v-15h4Z" transform="translate(-17.5 -17.5)"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_5" data-name="Ebene 5" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><path class="cls-1" d="M38.47,44.05a17.58,17.58,0,0,1,1.11-2.58c2.15-4,5.8-6.72,10-6.72,6.62,0,12,6.83,12,15.25s-5.38,15.25-12,15.25c-3.89,0-7.35-2.35-9.54-6" transform="translate(-17.5 -17.5)"/><polygon class="cls-3" points="22.2 34.48 22.2 34.48 16.7 23.98 28.2 23.98 22.2 34.48"/></svg>
                        </span>
                    </div> 
                </div>
                <div>
                    <div>Name Attribution & Sharing under the same Conditions</div>
                    <ul class="mt-32">
                        <li>
                            You may use this dataset for non-commercial and commercial purposes.
                        </li>
                        <li>
                            An indication of source is mandatory (author, title and link to the dataset).
                        </li>  
                        <li>
                            The work must be redistributed under the same license after modifications.
                        </li>    
                    </ul>
                    <div class="display-flex mt-32">
                        <span class="svg-container">
                            <svg id="Ebene_6" data-name="Ebene 6" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><path class="cls-2" d="M47.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/><path class="cls-2" d="M68.82,55.83a9.5,9.5,0,1,1,.37-11.14" transform="translate(-17.5 -17.5)"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_7" data-name="Ebene 7" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><circle class="cls-2 cls-3" cx="32.5" cy="17" r="5"/><path class="cls-2 cls-3" d="M60,43.68a3.18,3.18,0,0,0-3.18-3.18H43.18A3.18,3.18,0,0,0,40,43.68V55.5h4v15H56v-15h4Z" transform="translate(-17.5 -17.5)"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg"><path class="cls-1 cls-3" d="M44.53,57.37c.2,3.09,1.61,5.52,5.66,5.52,3.23,0,5.13-1.63,5.13-4.86,0-3-1.49-4.63-6.2-6.49-7-2.7-10-5.17-10-10.65s4.29-9.13,10.77-9.13c7.87,0,10.71,3.85,10.88,9.17H54.9c-.12-2.6-1.35-4.87-5.07-4.87-3.18,0-4.6,1.76-4.6,4.43s1.6,4.12,6.72,6.1c6.94,2.64,9.55,5.67,9.55,10.83,0,5.88-3.68,9.77-11.54,9.75-8.17,0-11.23-4.06-11.46-9.8Zm3.3-23.79V29h4.73v4.62Zm0,37.46V66.12h4.73V71Z" transform="translate(-17.5 -17.5)"/><circle class="cls-2" cx="32.5" cy="32.5" r="30"/><line class="cls-2" x1="5.75" y1="19.75" x2="59.25" y2="45.25"/></svg>
                        </span>
                        <span class="svg-container">
                            <svg id="Ebene_9" data-name="Ebene 9" xmlns="http://www.w3.org/2000/svg"><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><circle class="cls-1" cx="32.5" cy="32.5" r="30"/><path class="cls-1" d="M38.47,44.05a17.58,17.58,0,0,1,1.11-2.58c2.15-4,5.8-6.72,10-6.72,6.62,0,12,6.83,12,15.25s-5.38,15.25-12,15.25c-3.89,0-7.35-2.35-9.54-6" transform="translate(-17.5 -17.5)"/><polygon class="cls-3" points="22.2 34.48 22.2 34.48 16.7 23.98 28.2 23.98 22.2 34.48"/></svg>
                        </span>
                    </div> 
                </div>                
                <div>
                    <div>Name Attribution & Non-Commercial & Sharing under the same Conditions</div>
                    <ul class="mt-32">
                        <li>
                            You may use this dataset for non-commercial purposes.
                        </li>
                        <li>
                            Use of the dataset for commercial purposes is not allowed.
                        </li>  
                        <li>
                            An indication of source is mandatory (author, title and link to the dataset).
                        </li>
                        <li>
                            The work must be redistributed under the same license after modifications.
                        </li>    
                    </ul>
                </div>           
            </div>         
        </div>
    </div>
</div>      
</template>

<script>
export default {
  name: 'TermsOfUse'
}
</script>

<style>
.cls-1,.cls-2 {
    fill:none;
    stroke:#4e4d4d;
    stroke-miterlimit:10;
}
.cls-1{
    stroke-width:5px;
}
.cls-2{
    stroke-width:4px;
}
.cls-3 {
    fill:#4e4d4d;
    stroke: none;
}
.svg-container {
  width: 5em;
  height: 72px;
}
@media (max-width: 768px) {
  .gray-text-container {  
    padding: 16px 0px 16px 24px;
  }
  .custom-container {
    padding: 8px 4px;
  }
}
</style>


