<template>
    <footer class="footer mt-64">
        <div class="footer-green-nav navbar">
            <div class="container">
              <ul class="nav navbar-nav">
                <li>
                  <router-link :to="{ name: 'AboutUs', params: { lang: $route.params.lang } }">{{ $t('footer.nav.about_us') }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Data', params: { lang: $route.params.lang, id:1 } }">{{ $t('footer.nav.data') }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'CunaProduction', params: { lang: $route.params.lang } }">{{ $t('footer.nav.cuna_prod') }}</router-link>
                </li>
                <li>
                   <a href="https://www.fraunhofer.de/en/data-protection-policy.html" target="_blank">{{ $t('footer.nav.terms') }}</a>
                </li>
               <!-- <li>
                  <router-link to="/data-governance">Data Governance</router-link>
                </li> -->
              </ul>
            </div>
        </div>

      <div class="navbar footer-white">
        <div class="container">
          <ul class="nav navbar-nav mt-20">
            <li>
              <router-link :to="{ name: 'Impressum', params: { lang: $route.params.lang } }">Impressum</router-link>
            </li>
            <li>
              <a href="//www.ki-reallabor.de/en" target="_blank">www.ki-reallabor.de/en</a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li style="width: 296px;">
              <img class="img-responsive mt-32" src="/img/Logo_Plattform_I40.svg">
            </li>
            <li style="width: 288px;">
              <img class="img-responsive" src="/img/BMWi_en.svg">
            </li>
          </ul>
        </div>
      </div>

    </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>


