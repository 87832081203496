<template>
    <div class="container roman-headers">
      <div class="data-form col-md-8 col-sm-8">
        <div class="tile">
          <div>
              <div v-if="alert.msg" :class="alert.class" role="alert">
                {{alert.msg}}
              </div>
              <div v-if="loading">
                <Loader/>              
              </div>
              <p class="text-center mt-20 mb-20">{{ $t('reset_password.title') }}</p>
              <form novalidate>              

                <div class="form-group" :class="{ 'error': v$.user.password.$error || error.invalid_password}">
                    <div class="display-flex data-error"><span class="font-normal-12">{{ $t('reset_password.form.password') }}:</span></div>
                    <input type="password" class="form-control font-normal-12" v-model="user.password" placeholder="********" autocomplete="off" @blur="v$.user.password.$touch" required>
                    <div class="data-error font-normal-12" v-if="error.invalid_password"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{error.invalid_password}}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.password.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('reset_password.form.error.pass_req') }}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.password.minLength.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('reset_password.form.error.pass_min') }}</span></div>
                </div>

                <div class="form-group" :class="{ 'error': v$.user.confirmPassword.$error || (user.confirmPassword && user.password != user.confirmPassword) }">
                    <div class="display-flex data-error"><span class="font-normal-12">{{ $t('reset_password.form.confirm') }}:</span></div>
                    <input type="password" class="form-control font-normal-12" v-model="user.confirmPassword" autocomplete="off" @blur="v$.user.confirmPassword.$touch" placeholder="********" required>
                    <div class="data-error font-normal-12" v-if="v$.user.confirmPassword.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('reset_password.form.error.confirm_pass_req') }}</span></div>
                    <div class="data-error font-normal-12" v-if="user.confirmPassword && user.password != user.confirmPassword"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('reset_password.form.error.confirm_pass_inv') }}</span></div>
                </div>

                <div class="form-group float-right">
                    <input type="button" v-bind:value="$t('reset_password.form.button')" class="btn btn-primary btn-orange font-normal-12 create-btn mb-20" v-on:click="validateForm">
                </div>
            </form>
          </div>
          </div>
      </div>
    </div>   
</template>

<script>
import AuthService from './service/AuthService'
import AppConstant from '../../config/app-constant'
import Loader from '@/components/Loader.vue'
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  name: 'ResetPassword',
  components: {
    Loader
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true }) };
  },
  data () {
    return {
      user: {
        password: '',
        confirmPassword: ''
      },      
      error: {        
        
      },
      formSubmitted: false,
      loading: false,
      lang: 'en',
      alert: {

      }
    }
  },
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required
      }
    }
  },
  mounted () {
    document.body.classList.add('green-bg-img');
    document.getElementById('header-nav').classList.remove('nav-img');
  },
  destroyed () {
    document.body.classList.remove('green-bg-img');
    document.getElementById('header-nav').classList.add('nav-img');
  },
  async created() {
    this.lang = this.$route.params.lang ? this.$route.params.lang : 'en';
    this.resetCheck();
  },
  methods:{
    validateForm: function () {
      window.scrollTo(0,0);
      this.formSubmitted = true;
      this.error = {};
      this.alert = {};
      this.v$.$touch()
      if (this.v$.$invalid || (this.user.password != this.user.confirmPassword)) {
         return;
      } else {
        this.resetPassword();
      }
    },
    hideErrorMsg: function (route){
      setTimeout(()=>{
          this.$set(this.alert, 'class', '');
          this.$set(this.alert, 'msg', '');
          if (route) {
            this.$router.push({ name: route });
          }          
      },2000);
    },
    resetCheck: async function () {
      this.loading = true; 
      const data = await AuthService.resetCheck(this.$route.params.id, this.$route.query.t);
      this.loading = false; 
      if (!data.success) {
        if (data.error) {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', data.error.msg);          
        } else {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', AppConstant[`SOMETHING_WENT_WRONG_MSG_${this.lang}`]);
        }
        this.hideErrorMsg('Home');
      }      
    },
    resetPassword: async function () {
      this.loading = true;
      this.user.id = this.$route.params.id;
      const data = await AuthService.resetPassword(this.user);
      this.loading = false; 
      if (data.success) {
        this.$set(this.alert, 'class', AppConstant.ALERT_SUCCESS_CLASS);
        this.$set(this.alert, 'msg', AppConstant[`RESET_SUCCESS_MSG_${this.lang}`]);
        this.formSubmitted = false;
        this.hideErrorMsg('Login');
      } else {
        if (data.error) {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', data.error.msg);
        } else {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', AppConstant[`SOMETHING_WENT_WRONG_MSG_${this.lang}`]);
        }
        this.hideErrorMsg();    
      }
    }
  }
}
</script>

<style>
.green-bg-img {
  background-image: url("../../../public/img/green_background_full.png");
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 1900px) {
  .login {
    background-size: 100% 35%;
  }
}

.data-form {
  float: none;
  margin: auto;
  color: var(--grayfontlight);
}

.green-bg-img .tile {
  width: 100%;
  height: auto;
  margin-top: 64px;
  box-shadow: 5px 5px 5px rgb(0 0 0 /10%);
  border-radius: 6px;
}

.tile > div {
  padding: 24px 88px;
}

.form-group {
  margin-bottom: 18px;
}

.green-bg-img .form-group .form-control {
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 4px rgb(0 0 0 /5%);
}

.create-btn {
  border-radius: 4px;
  width: 124px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

@media (max-width: 768px) {
  .tile > div {
    padding: 24px 48px;
  }
}
</style>
