<template>
    <div class="container custom-container roman-headers">
        <div class="contact-us-heading data-form col-md-8 col-sm-8">
        <h1 class="text-center font-bold mb-32">{{ $t('contact_us.title') }}</h1>
        <h5>{{ $t('contact_us.desc1') }}</h5>
        <h5>{{ $t('contact_us.desc2') }}</h5>
        <h5>{{ $t('contact_us.desc3') }}</h5> 
        <div class="tile contact-us-form">
          <div>
            <div v-if="alert.msg" :class="alert.class" role="alert">
                {{alert.msg}}
            </div>
            <div v-if="loading">
                <Loader/>              
            </div>  
            <form novalidate>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6" :class="{ 'error': v$.message.firstname.$error }">
                            <div class="display-flex data-error"><span class="font-normal-12">{{ $t('contact_us.form.firstname') }}</span><span class="ml-4 asterisk">*</span></div>
                            <input type="text" class="form-control font-normal-12" :placeholder="$t('contact_us.form.firstname')" v-model="message.firstname" @blur="v$.message.firstname.$touch" required>
                            <div class="data-error font-normal-12" v-if="v$.message.firstname.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.firstname') }}</span></div>
                        </div>
                        <div class="col-md-6 col-sm-6" :class="{ 'error': v$.message.lastname.$error }">
                            <div class="display-flex data-error"><span class="font-normal-12">{{ $t('contact_us.form.lastname') }}</span><span class="ml-4 asterisk">*</span></div>
                            <input type="text" class="form-control font-normal-12" :placeholder="$t('contact_us.form.lastname')" v-model="message.lastname" @blur="v$.message.lastname.$touch" required>
                            <div class="data-error font-normal-12" v-if="v$.message.lastname.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.lastname') }}</span></div>
                        </div>
                    </div>                                                          
                </div>                
                <div class="form-group" :class="{ 'error': v$.message.company.$error }">
                    <div class="display-flex data-error"><span class="font-normal-12">{{ $t('contact_us.form.company') }}</span><span class="ml-4 asterisk">*</span></div>
                    <input type="text" class="form-control font-normal-12" :placeholder="$t('contact_us.form.company')" v-model="message.company" @blur="v$.message.company.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.message.company.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.company') }}</span></div>
                </div>
                <div class="form-group" :class="{ 'error': v$.message.email.$error }">
                    <div class="display-flex data-error"><span class="font-normal-12">{{ $t('contact_us.form.email') }}</span><span class="ml-4 asterisk">*</span></div>
                    <input type="email" class="form-control font-normal-12" :placeholder="$t('contact_us.form.email')" v-model="message.email" @blur="v$.message.email.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.message.email.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.email_req') }}</span></div>            
                    <div class="data-error font-normal-12" v-if="v$.message.email.email.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.email_inv') }}</span></div>
                </div>
                <div class="form-group" :class="{ 'error': v$.message.body.$error }">
                    <div class="display-flex data-error"><span class="font-normal-12">{{ $t('contact_us.form.message') }}</span><span class="ml-4 asterisk">*</span></div>
                    <textarea class="form-control font-normal-12" rows="7" v-model="message.body" @blur="v$.message.body.$touch" required></textarea>                   
                    <div class="data-error font-normal-12" v-if="v$.message.body.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('contact_us.form.error.message') }}</span></div>
                </div>
                <div class="form-group float-left">
                    <input type="button" :value="$t('contact_us.form.button')" class="btn btn-primary btn-orange font-normal-12 create-btn mb-20" v-on:click="validateForm">
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>    
</template>

<script>
import useVuelidate from "@vuelidate/core";
import DataService from './service/DataService'
import { required, email } from "@vuelidate/validators";
import AppConstant from '../config/app-constant';
import Loader from '@/components/Loader.vue'

export default {
  name: 'ContactUs',
  components: {
    Loader
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true }) };
  },
  data () {
    return {
      message: {
        firstname:'',
        lastname:'',
        company: '',
        email: '',
        body: ''
      },      
      loading: false,
      lang: 'en',
      alert: {

      }
    }
  },
  validations: {
    message: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      },
      company: {
        required
      },
      body: {
        required
      }
    }
  },
  methods:{
    validateForm: function () {
      window.scrollTo(0,0);
      this.formSubmitted = true;
      this.alert = {};
      this.v$.$touch()
      if (this.v$.$invalid) {
         return;
      } else {
        this.contactUs();
      }
    },
    hideErrorMsg: function (homeRedirect){
      setTimeout(()=>{
          this.$set(this.alert, 'class', '');
          this.$set(this.alert, 'msg', '');
          if (homeRedirect) {
            this.$router.push({ name: 'Home' });
          }
      },3000);
    },
    contactUs: async function () {
      this.loading = true; 
      const data = await DataService.contactUs(this.message);
      this.loading = false; 
      if (data.accepted) {
        this.$set(this.alert, 'class', AppConstant.ALERT_SUCCESS_CLASS);
        this.$set(this.alert, 'msg', AppConstant[`CONTACT_US_SUCCESS_MSG_${this.lang}`]);
        this.formSubmitted = false;
        this.hideErrorMsg(true);
      } else {
        this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
        this.$set(this.alert, 'msg', AppConstant[`SOMETHING_WENT_WRONG_MSG_${this.lang}`]);
        this.hideErrorMsg();
      }
    }
  },
  created() {
    this.lang = this.$route.params.lang ? this.$route.params.lang : 'en';
  },
  mounted () {
    document.body.classList.add('green-bg-img');
    document.getElementById('header-nav').classList.remove('nav-img');
  },
  destroyed () {
    document.body.classList.remove('green-bg-img');
    document.getElementById('header-nav').classList.add('nav-img');
  },
}
</script>

<style scoped>
.green-bg-img {
    background-size: 100% 76%;
}
.contact-us-heading {
  color: white;
}
.tile > div {
    padding: 40px 24px;
}

.contact-us-form {
    color: var(--grayfontlight);
}
.asterisk {
    color: #dc3545;
}
</style>
