<template>
  <div class="showcase-card">
    <img v-if="!showcase.imgUrl" src="/img/showcase_example_03.png" />
    <img v-if="showcase.imgUrl" v-bind:src="showcase.imgUrl" alt="Show case 3" />
    <div class="caption">
      <h3 class="roman-headers">
        <router-link :to="{name: 'Showcase', params: {'id': showcase.name, lang: $route.params.lang }}" class="roman-headers data-title">
          {{showcase.title}}</router-link>
      </h3>
      <p class="showcase-date">{{ showcase.metadata_modified | moment("MMM DD, YYYY") }}</p>
      <p>
        {{showcase.description.substring(0,200)+".."}}
      </p>
    </div>
  </div> 
</template>

<script>
export default {
  name: "ShowcaseTile",
  props: {
    showcase: Object,
  },
};
</script>

