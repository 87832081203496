<template>
  <div>
    <Header />
      <router-view/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Base',
  components: {
    Header,
    Footer
  },
  data() {
    return {}
  }
}
</script>
