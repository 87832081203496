<template>
  <div class="container custom-container" v-if="dataInfo.dataset_title">
    <h1 class="text-center roman-headers">{{ $t("dataset.title") }}</h1>

    <h2 class="text-center">{{dataInfo.dataset_title}}</h2>

    <div class="gray-text-container mt-20">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <p>{{ $t("dataset.last_updated") }}</p>
          <p>{{ dataInfo.resources[dataInfo.resources.length-1].last_modified | moment("MMM DD, YYYY") }}</p>
        </div>
        <div class="col-md-6 col-sm-6">
          <p>{{ $t("dataset.organization") }}</p>
          <p>{{ dataInfo.org_title }}</p>
        </div>
      </div>

      <div class="keywords data-flex-prop">
        <div v-for="tag in dataInfo.category" :key="tag">          
          <button class="btn btn-primary btn-gray">{{tag}}</button>
        </div>
      </div>

      <div class="mt-50">
        <h3 class="roman-headers">{{ $t("dataset.desc") }}</h3>
        <p>{{dataInfo.description}}</p>
      </div>

      <div class="mt-50">
        <h3 class="roman-headers">{{ $t("dataset.date_explore") }}</h3>
        <div id="data-explorer">
          <table>
            <tr id="selectAllTable">
              <td><input type="checkbox" :checked="selectAll" @change="selectAllFiles($event)" /></td>
              <td style="width:100%">Select All</td>
              <td style="width:100%"><button class="btn btn-primary btn-green" 
              @click="downloadData()" :disabled="downloading || dataURLs.length == 0">
                {{downloading ? "Downloading": "Download"}} {{dataURLs.length ? dataURLs.length : ''}} Data Package</button></td>
            </tr>
          </table>
          <div id="dataFileAndPreview">
            <div id="fileList">
              <table>
                <tr v-for="resource in dataInfo.resources" :key="resource.id">
                  <td><input type="checkbox" v-model="resource.checked" @click="selectFiles($event, resource.name, resource.path)" /></td>
                  <td><img width="16px" :src="resource.fileIcon"></td>
                  <td :class="resource.name === previewFilename ? 'selectedPreview': ''"><a @click="showPreview(resource.name, resource.path)">{{resource.name}}</a></td>
                  <td><a :href="resource.path"><i class="fas fa-download"></i></a></td>
                </tr>
              </table>
            </div>
            <div v-if="loadingPreview" class="previewBox d-flex-center">
              <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>
            <div v-if="previewErrorMsg" class="previewBox d-flex-center">
              <span>{{previewErrorMsg}}</span>
            </div>
            <div v-if="!previewErrorMsg & !loadingPreview" id="filePreview" class="mt-24">
              <table>
                <tr>
                  <th v-for="(column, columnIndex) in csvParsedData.columns" :key="columnIndex">{{column}}</th>
                </tr>
                <tr v-for="(row, rowIndex) in csvParsedData" :key="rowIndex">
                  <td v-for="(data, index) in row" :key="index">{{data}}</td>
                </tr>
              </table>
            </div> 
          </div>
        </div>
      </div>

      <div class="mt-50">
        <h3 class="roman-headers">{{ $t("dataset.additional_info") }}</h3>
        <div id="meta-info" style="overflow-x: auto">
          <table>
            <tr>
              <td>{{ $t("dataset.source") }}</td>
              <td>{{dataInfo.source}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.version") }}</td>
              <td>{{dataInfo.version}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.organization") }}</td>
              <td>{{dataInfo.org_title}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.author") }}</td>
              <td>{{dataInfo.author}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.author_email") }}</td>
              <td>{{dataInfo.author_email}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.issue_date") }}</td>
              <!-- TODO: Check which data should be the issue data? -->
              <td>{{dataInfo.resources[0].created | moment("MMM DD, YYYY")}}</td>
            </tr>
            <tr>
              <td>{{ $t("dataset.language") }}</td>
              <td>{{dataInfo.lang}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mt-50">
        <h3 class="roman-headers">{{ $t("dataset.que") }}</h3>
        <p>{{ $t("dataset.que_desc") }}</p>
        <button class="btn btn-primary btn-green">{{dataInfo.org_title}}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

a:hover {
 cursor:pointer;
}

/* The description column is removed, hence commenting this out.
.file-desc div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

/* Common padding size for all td */
#selectAllTable td, #fileList td {
  padding: 24px 24px;
}

/* Reducing left padding of filename so it appears near to fileicon */
#data-explorer #fileList td:nth-child(3) {
  padding-left: 8px;
}

/* Reducing left and right padding for file icon */
#data-explorer #fileList td:nth-child(2) {
  padding: 24px 8px;
}

#fileList table td a {
  color: var(--grayfont);
}

/* For checkbox */
#fileList table td input {
  margin-top: 10px;
}

#fileList table td.selectedPreview {
  font-weight: bold;
}

#dataFileAndPreview {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#filePreview {
  flex: 1 1;
  border-radius: 8px;
  background: #fff;
  display: block;
  overflow-x: auto;
}

/** A white box to show loader or preview error messages */
.previewBox {
  margin-top: 24px;
  height: 208px;
  width: 592px;
  border-radius: 8px;
  background: #fff;
}

.fa-spinner {
  color: var(--grayfontdark);
}

#filePreview table th, #filePreview table td {
  padding: 8px 12px;
  word-break: break-word;
  line-height: 16px;
  min-width: 104px;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

/** Removing border on the bottom edge */
#filePreview table tr:last-child td {
  border-bottom: none;
}

</style>

<script>

import FileSaver from 'file-saver';
import * as d3 from "d3-dsv";

export default {
  name: "Dataset",
  mounted() {
    document.body.classList.add("dataset_");
  },
  destroyed() {
    document.body.classList.remove("dataset_");
  },
  data() {
    return {
      dataInfo: {},
      dataURLs: [],
      selectAll: false,
      downloading: false,
      fileIcon: '',
      icon_img: '',
      csvParsedData: [],
      loadingPreview: false,
      previewFilename: '',
      previewErrorMsg: ''
    }
  },
  methods: {
    async fetchData() {
      let searchUrl = '/api/search?q=name:';
      if (this.$route.params.id) {
        searchUrl = searchUrl + this.$route.params.id;
      }
      const res = await fetch(searchUrl)
      const data = await res.json();

      if (data.results.length > 0) {
        this.dataInfo = data.results[0];
        this.icon_img = `/img/Icon_${this.dataInfo.resources[0].format}.svg`;

        this.getFileIconPath();
        this.showPreview(this.dataInfo.resources[0].name, this.dataInfo.resources[0].path);
      }
    },
    async getFileIconPath() {
      this.dataInfo.resources.forEach(resource => {
        var fileName = resource.name.toLowerCase();
        if (fileName.includes("csv")) {
          resource.fileIcon = "/img/icons/csv.svg";
        } else if (fileName.includes("ipynb")) {
          resource.fileIcon = "/img/icons/ipynb.svg";
        } else if (fileName.includes("pdf")) {
          resource.fileIcon = "/img/icons/pdf.svg";
        } else if (fileName.includes("txt")) {
          resource.fileIcon = "/img/icons/txt.svg";
        } else if (fileName.includes("ppt")) {
          resource.fileIcon = "/img/icons/ppt.svg";
        } else {
          resource.fileIcon = "/img/icons/img.svg";
        }     
      });
    },
    async addCheckProp(fileName, checked) {
      this.dataInfo.resources.forEach(resource => {
        if (resource.name == fileName) {
          resource.checked = checked
        }
      });
    },
    async selectAllFiles(event) {

      if (event.target.checked) {
        this.dataInfo.resources.forEach(resource => this.dataURLs.push({"name": resource.name, "url": resource.path}));
        this.selectAll = true;
        this.dataInfo.resources.forEach(resource => resource.checked = true);
      } else {
        this.dataURLs = [];
        this.selectAll = false;
        this.dataInfo.resources.forEach(resource => resource.checked = false);
      }
    },
    async selectFiles(event, fileName, fileURL) {
      
      if (event.target.checked) {
        this.dataURLs.push({"name": fileName, "url": fileURL});        
      } else {
        this.dataURLs = this.dataURLs.filter(function(dataURL) {
          return dataURL.name !== fileName;
        });
      }
      this.addCheckProp(fileName, event.target.checked);
    },
    async downloadData() {

      this.downloading = true;
      let downloadUrl = '/api/downloadZip';

      fetch(downloadUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.dataURLs)
      }).then((response) => {
        return response.text();
      }).then((zipAsBase64) => {

        var sliceSize = 512;
        const byteCharacters = atob(zipAsBase64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: "application/zip"});

        FileSaver.saveAs(blob, 'data.zip'); 
        
        this.downloading = false;   // Enable download button
        this.dataURLs = [];   // Empty the request payload
        this.selectAll = false;   // Deselect all checkboxes
        this.dataInfo.resources.forEach(resource => resource.checked = false);
      });
    },
    showPreview: async function(filename, url) {

        this.previewErrorMsg  = '';
        this.previewFilename = filename;

        /** Checking file extension and only fetching data for CSV file. */
        if (filename.split('.').pop().toLowerCase() != 'csv') {
          this.csvParsedData = [];
          this.previewErrorMsg = this.$t("dataset.no_preview");
        } else {
          this.loadingPreview = true;
          const res = await fetch('/api/downloadCSV', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({"url": url})
          });
          var data = await res.json();
          
          try {
            var csvRows = d3.csvParse(data, function (d, i) {
              
              /* i = index, d = row
              All rows are going through this function, below condition does not seem
              to limit parsing operation, it just make sure to return 5 rows. */
              if (i < 5) return d;
              
            });
          } catch (err) {
            this.previewErrorMsg = this.$t("dataset.error_preview");
          }

          this.csvParsedData = csvRows;
        }
        this.loadingPreview = false;
    }
  }, 
  async created() {
    this.fetchData();
  }
};
</script>
