<template>
    <div class="container custom-container roman-headers">
        <h1 class="text-center mb-32">Cuna Production</h1>
        <h3 class="text-center">{{ $t("cuna_production.desc") }}</h3>        
        <div class="gray-text-container">
            <h3>{{ $t("cuna_production.h_1") }}</h3>
            <p>{{ $t("cuna_production.p_1") }}</p>

            <p>{{ $t("cuna_production.p_2") }}</p>

            <p>{{ $t("cuna_production.p_3") }}</p>

            <h3>{{ $t("cuna_production.h_2") }}</h3>

            <p>{{ $t("cuna_production.p_4") }}</p>

            <div class="mt-50 mb-50">
                <a class="darkgreen-color" href="https://smartfactory-owl.de/cuna-realproduktion/">
                https://smartfactory-owl.de/cuna-realproduktion/</a>
            </div>

            <div class="flex-props">
                <img src="/img/cuna_production_image.jpg" class="img-responsive" />
            </div>

            <hr/>
            <!-- Image and button div -->
            <iframe src="DashboardPrototyp/index.html" width="100%" height="840px" style="border: none; margin: auto;"></iframe>
            <!-- End of Image and button div -->

        </div>
    </div>   
</template>

<script>

export default {
  name: 'CunaProduction',
  created() {
    window.scrollTo({
      top: 420,
      behavior: 'smooth'
    });
  }
}

</script>

<style>
.nav-img {
    min-height: 136px;
}
</style>