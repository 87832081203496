import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueMoment from 'vue-moment'
import Home from '../components/Home'
import Login from '../components/auth/Login'
import Logout from '../components/auth/Logout'
import Registration from '../components/auth/Registration'
import Base from '../views/Base'
import Data from '../views/Data'
import TermsOfUse from '../views/TermsOfUse'
import Impressum from '../views/Impressum'
import AboutUs from '../views/AboutUs'
import CunaProduction from '../views/CunaProduction'
import Showcases from '../views/Showcases'
import Showcase from '../views/Showcase'
import InProgress from '../views/InProgress'
import Dataset from '../views/Dataset'
import DataGovernance from '../views/DataGovernance'
import ContactUs from '../views/ContactUs'
import ForgotPassword from '../components/auth/ForgotPassword'
import ResetPassword from '../components/auth/ResetPassword'

Vue.use(VueRouter)
Vue.use(VueMoment)
Vue.use(Vuex)

const routes = [
  {
    path: '/:lang([a-z][a-z])?',
    component: Base,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          showHomeMessage: true
        }
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          hideSearch: true
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        component: Logout
      },
      {
        path: 'register',
        name: 'Registration',
        component: Registration,
        meta: {
          hideSearch: true
        }
      },
      {
        path: 'data/:id?',
        name: 'Data',
        component: Data,
        meta: {
          activeData: true
        }
      },      
      {
        path: 'terms-of-use',
        name: 'TermsOfUse',
        component: TermsOfUse
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: Impressum
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
          activeAbout: true,
          hideSearch:true
        }
      },
      {
        path: 'cuna-production',
        name: 'CunaProduction',
        component: CunaProduction,
        meta: {
          activeCuna: true,
          hideSearch:true
        }
      },
      {
        path: 'showcases/:id?',
        name: 'Showcases',
        component: Showcases,
        meta: {
          activeShowcases: true,
          hideSearch:false
        }
      },
      {
        path: 'showcase/:id?',
        name: 'Showcase',
        component: Showcase,
        meta: {
          activeShowcases: true,
          hideSearch:true
        }
      },
      {
        path: 'dataset/:id',
        name: 'Dataset',
        component: Dataset,
        props: true,
      },
      {
        path: 'data-governance',
        name: 'DataGovernance',
        component: DataGovernance,
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
          activeContact: true,
          hideSearch:true,
        }
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          hideSearch: true
        }
      },
      {
        path: 'reset/:id',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          hideSearch: true
        }
      },
    ] 
  }, {
    path: '/in-progress',
    name: 'InProgress',
    component: InProgress
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const store = new Vuex.Store({
  state: {
    token: '',
    username: ''
  },
  actions: {
    loginSession(context, data) {
      localStorage.setItem('session', JSON.stringify(data))
      context.commit('setSession', data)
    },
    getSession(context) {
      const session = localStorage.getItem('session')
      if (session && typeof session === 'string' && session !== '') {
        const data = JSON.parse(session)
        context.commit('setSession', data)
      }
    },
    logoutSession(context) {
      context.commit('logoutSession')
    }
  },
  mutations: {
    setSession(state, n) {
      state.token = n.token
      state.username = n.username
    },
    logoutSession(state) {
      localStorage.clear()
      state.token = ''
      state.username = ''
    }
  }
})

export { store }
export default router