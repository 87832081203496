<template>
  <div class="category-tiles">

    <div v-if="!showMore">
      <div :key="category.name" v-for="category in categoryList.slice(0, 5)"
        :class="category.selected ? 'selected': ''"
        class="gray-tile display-flex border-radius-none cursor-pointer"    
        @click="$emit('data-search', category.name)">
        <span class="category-tile-line"></span>
        <span class="category-tile">{{category.name}}</span>
        <span class="data-info display-flex d-flex-align-center font-normal-12 ml-8">{{category.count}}</span>
      </div>
    </div>
    <div v-if="showMore">
      <div :key="category.name" v-for="category in categoryList"
        :class="category.selected ? 'selected': ''"
        class="gray-tile display-flex border-radius-none cursor-pointer"    
        @click="$emit('data-search', category.name)">
        <span class="category-tile-line"></span>
        <span class="category-tile">{{category.name}}</span>
        <span class="data-info display-flex d-flex-align-center font-normal-12 ml-8">{{category.count}}</span>
      </div>
    </div>
    <div v-if="categoryList.length > 5" class="text-center gray-tile p-4 position-relative cursor-pointer" @click="showMore = !showMore">
      <span v-if="!showMore">Show more tags</span>
      <span v-if="showMore">Show fewer tags</span>
      <span :class="!showMore ? 'more-dropdown': 'more-up-dropdown'" class=""></span>
    </div>

  </div>

</template>

<script>

export default {
  name: "Categories",
  data() {
      return {
          showMore: false
      }
    },
  props: {
    categoryList: Array,
  }
};

</script>

<style scoped>

.gray-tile.selected {
  background-color: var(--lightgreen);
}

.more-dropdown::before {
    content: "\276F";
    position: absolute;
    right: 20px;
    bottom: 5px;
    z-index: 99999;
    display: inline-block;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: var(--grayfont);
}

.more-up-dropdown::before {
    content: "\276E";
    position: absolute;
    right: 20px;
    bottom: 5px;
    z-index: 99999;
    display: inline-block;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: var(--grayfont);
}

</style>