<template>
    <div></div>
</template>

<script>

import AuthService from "../auth/service/AuthService";

export default {
  name: 'Logout',
  created () {
    this.revokeUserToken()
    this.$store.commit('logoutSession');
    this.$router.push({path: '/' });
  },
  methods: {
    async revokeUserToken () {
      await AuthService.revokeUserToken({
        token: this.$store.state.token
      });
    }
  }
}

</script>
