
class DataService {

    static async fetchData(searchUrl) {
        const res = await fetch(searchUrl);
        const data = await res.json();
        return data;
    }

    static async contactUs(message) {
        const res = await fetch("/api/send_email", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        });
        const data = await res.json();
        return data;
    }

}

export default DataService;