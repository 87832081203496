<template>
    <div class="overlay">
        <div class="loader">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
        </div>              
    </div>    
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style scoped>
.loader {
  position: absolute;
  top:50%;
  left: 50%;
}

.fa-spinner {
    color: white;
}

.overlay {
  background: #000;  
  position: fixed;
  z-index:9999;
  width: 100%;
  top: 0;                 
  right: 0;               
  bottom: 0;
  left: 0;
  opacity: 0.5;
}
</style>


