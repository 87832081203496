<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
  name: 'App',
  created() {
    this.getSessionInfo()
  },
  methods: {
    ...mapActions(['getSession']),
    getSessionInfo() {
      this.getSession();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
