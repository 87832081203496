<template>
    <div>
        <div class="wrap">
            <div class="tile col-md-3 col-sm-4 col-xs-8">
                <h3 class="mt-16 text-center color-gray">Academic</h3>
                <div class="text pt-0">                
                    <div>{{ $t('home.middle_sec.left_para') }}</div>              
                </div>                
                <img src="/img/academic_icon.png" alt="Academic sign" class="center">
                <div class="mt-20">
                    <router-link to="/data/1"><button class="btn btn-primary btn-green">
                        <span> {{ $tc('home.middle_sec.buttons', 0) }}</span>
                    </button></router-link>
                </div>                
            </div>            
            <div class="tile col-md-3 col-sm-4 col-xs-8">
                <h3 class="mt-16 pt-0 text-center color-gray">Industry</h3>
                <div class="text pt-0">                
                    <div>{{ $t('home.middle_sec.right_para') }}</div>          
                </div>
                <img src="/img/industry_icon.png" alt="Industry sign" class="center">
                <div class="mt-20 popular-cases">
                    <router-link :to="{ name: 'Showcases', params: { lang: $route.params.lang, id:1 } }" ><button class="btn btn-primary btn-orange">
                        <span>{{ $tc('home.middle_sec.buttons', 1) }}</span>
                    </button></router-link>                    
                </div>
                <div class="mt-20">                    
                    <router-link :to="{ name: 'ContactUs', params: { lang: $route.params.lang } }"><button class="btn btn-primary btn-green">
                        <span>{{ $tc('home.middle_sec.buttons', 2) }}</span>
                    </button></router-link>
                </div>
            </div>
        </div>
        <div class="live-data">
            <div class="header-text text-center">
            <h1 v-html="$t('home.cuna_title')"></h1>
            </div>
            <div class="flex-props mt-50">
                <div>
                    <iframe width="496" height="300"
                        src="https://www.youtube.com/embed/nCbWS87HTJQ">
                    </iframe>
                </div>
                <div class="teaser-text">
                    <p class="data-text">{{ $t('home.cuna_desc') }}</p>
                    <router-link to="/cuna-production"><button class="btn btn-primary btn-green">
                        <span>Go to Cuna Production</span>
                    </button></router-link>
                </div>
            </div>
        </div>
        <h1 align="center" class="mt-50">Show cases</h1>
        <div id="cases_carousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#cases_carousel" data-slide-to="0" class="active"></li>
                <li data-target="#cases_carousel" data-slide-to="1"></li>
            </ol>

    
        <div class="carousel-inner">
            <div class="item active">
                <div class="carousel-cards">
                <div class="carousel-card">
                <div class="thumbnail">
                    <img src="/img/showcase_example_01.png" alt="Show case 1">
                    <div class="caption">
                    <h3>Collaborative Condition Monitoring</h3>
                    <p>Cross-company data exchange within the value chain, consisting of component/machine suppliers & factory operators, should make it possible to improve the reliability and service life of components, machines and plants.
                        On the way to an industrial data economy, the goal is to test further service-based business models.</p>
                    </div>
                    <p class="caption btn-align"><button href="#" class="btn btn-primary btn-darkgreen" role="button">To the show case</button></p>
                </div>
                </div>

                <div class="carousel-card">
                <div class="thumbnail">
                    <img src="/img/showcase_example_02.png" alt="Show case 2">
                    <div class="caption">
                    <h3>Smart product development through customer feedback</h3>
                    <p>How can I use meaningful data analytics to integrate customer feedback into my product development?</p>
                    </div>
                    <p class="caption btn-align"><button href="#" class="btn btn-primary btn-darkgreen" role="button">To the show case</button></p>
                </div>
                </div>
                </div>
            </div>

            <div class="item">
                <div class="carousel-cards">
                <div class="carousel-card">
                <div class="thumbnail">
                    <img src="/img/showcase_example_03.png" alt="Show case 3">
                    <div class="caption">
                    <h3>Collaborative Condition Monitoring</h3>
                    <p>Cross-company data exchange within the value chain, consisting of component/machine suppliers & factory operators, should make it possible to improve the reliability and service life of components, machines and plants.
                        On the way to an industrial data economy, the goal is to test further service-based business models.</p>
                    </div>
                    <p class="caption btn-align"><button href="#" class="btn btn-primary btn-darkgreen" role="button">To the show case</button></p>
                </div>
                </div>
                <div class="carousel-card">
                <div class="thumbnail">
                    <img src="/img/showcase_example_04.png" alt="Show case 4">
                    <div class="caption">
                    <h3>Smart product development through customer feedback</h3>
                    <p>How can I use meaningful data analytics to integrate customer feedback into my product development?</p>
                    </div>
                    <p class="caption btn-align"><button href="#" class="btn btn-primary btn-darkgreen" role="button">To the show case</button></p>
                </div>
                </div>
                </div>
            </div>
        </div>

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#cases_carousel" data-slide="prev">
      <!--<span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>-->
    </a>
    <a class="right carousel-control" href="#cases_carousel" data-slide="next">
      <!--<span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>-->
    </a>
  </div>
    </div>       
</template>

<script>
export default {
  name: 'Home',
  methods: {
    onDataClick: function(type) {
        let queryParam = Object.assign({}, this.$route.query);
        queryParam.tags = type;
        this.$router.push( {path: '/data/1', query: queryParam});
    }
  }
}
</script>

<style scoped>

.center {
    display: block;
    margin: auto;
    width: 40%;
}

.tile {
    position: relative;
    min-height: 432px;
    height: auto;
}

.tile img {
    margin-top: 8px;
}

.tile a {
    position: absolute;
    bottom: 20px;
    left: 32px;
    right: 32px;
}

.tile .popular-cases a {
    bottom: 72px;
}

</style>