<template>
  <div>
    <div v-if="!$store.state.token" class="gray-alert-msg d-flex-center d-flex-column">
      <div class="msg">{{ $t("data.msg_1") }}</div>
      <div class="msg">{{ $t("data.msg_2") }}</div>
      <div class="display-flex mt-4 nav">
        <span><router-link :to="{ name: 'Registration', params: { lang: $route.params.lang } }"  class="btn-primary btn-gray nav-btn alert-btn-padding">{{ $t('header.nav.register') }}</router-link></span>
        <span class="ml-24"><router-link :to="{ name: 'Login', params: { lang: $route.params.lang } }" class="btn-primary btn-orange login-btn nav-btn alert-btn-padding">
        {{ $t('header.nav.log_in') }}</router-link></span>
      </div>
    </div>
    <div id="datalist" class="container">
      <div class="row">
        <section class="col-md-3 col-sm-4 col-xs-4">
          
          <section class="section-title">{{ $t('data.categories') }}</section>
          <categories :category-list="categoryList" @data-search="dataSearch" />

          <div class="form-group">
            <label for="file-format" class="section-title">{{ $t('data.file_format') }}</label>
            
            <div class="keywords data-flex-prop">
              <button v-for="fileFormat in fileFormatList" :key="fileFormat.name" class="btn btn-gray"
                :class="fileFormat.selected ? 'selected': ''" @click="filterFileFormat(fileFormat)">
                {{fileFormat.name}} ({{fileFormat.count}})</button>
            </div>
          </div>
        </section>

        <section class="col-md-9 col-sm-8 col-xs-8">
            <h2>{{ $t('data.dataset') }}</h2>
            <div :key="data.id" v-for="data in datalist">
              <DataTile :dataTileInfo="data" />
            </div>
            <div v-if="datalist.length == 0 && !loading" class="display-flex d-flex-justify-center mt-50 text-center"><h1 class="header-text data-info">{{ $t('data.no_data') }}</h1></div>
            <div v-if="loading">
              <Loader/>              
            </div>
        </section>

      </div>
      <nav aria-label="Datasets" class="text-center" v-if="datalist.length > 0">
        <Paginate
          v-model="page"
          :page-count="totalPages"
          :click-handler="getPage"
          :prev-text="'&laquo'"
          :next-text="'&raquo;'"
          :container-class="'pagination'">
        </Paginate>
      </nav>    
    </div>
  </div>
</template>

<script>
import DataTile from '../components/DataTile.vue'
import Categories from '../components/Categories.vue'
import Paginate from 'vuejs-paginate'
import DataService from './service/DataService'
import Loader from '@/components/Loader.vue'


export default {
    name: 'Data',
    props: {
      
    },
    data() {
      return {
          datalist: [],
          categoryList: [],
          fileFormatList: [],
          query: {},
          fileFormatQuery: [],
          page: 1,
          globalQuery:'',
          currentPage: 0,
          totalPages: 0,
          loading: true,
      }
    },
    components: {
      DataTile,
      Categories,
      Paginate,
      Loader
    },
    methods: {
      async fetchData() {
        this.loading = true;
        let searchUrl = `/api/search?q=${this.globalQuery}`;

        /* If user is logged in, get user group using 'group_list_authz' API.
         If user is part of Academic group, get Academic + Commercial dataset
         If user is part of Commercial group, get Commercial dataset */
        
        let groupsFilter = '&fq=groups:"commercial"';
        if (this.$store.state.token) {
          const res = await fetch('/api/group_list_authz', {headers: {"Authorization": this.$store.state.token}})
          const data = await res.json()
          
          let academic_group = data.find(element => element.name == "academic")
          
          if (academic_group) {
            groupsFilter = '&fq=groups:"academic" OR groups:"commercial"';
          }
        }

        searchUrl = searchUrl + groupsFilter;

        /* Adding tags to the search url if query object contains it */
        if (Object.keys(this.query).length > 0) {
          let separator = searchUrl.includes('fq') ? ' ' : '&fq=';
          /* To add multiple tags in query */
          const query = Object.keys(this.query).join(`" AND tags:"`);
          searchUrl = searchUrl + `${separator}tags:"${query}"`;
        }
        /* Adding formats to the search url if fileFormatQuery object contains it  */
        if (this.fileFormatQuery.length > 0) {
          let separator = searchUrl.includes('fq') ? ' ' : '&fq=';
          /* Add multiple file formats */
          const formatQuery = this.fileFormatQuery.join(`" AND res_format: "`);
          searchUrl = searchUrl + `${separator}res_format:"${formatQuery}"`;
        }
        searchUrl = searchUrl + `&size=10&from=${this.currentPage}`;
        const data = await DataService.fetchData(searchUrl);
        this.loading = false;
        this.totalPages = data.pagination.totalPages;
        this.datalist = data.results;
        // Adding all the formats in fileFormatList in order to add 'selected' property
        this.fileFormatList = [];
        // key is the name of the file format
        for (let key in data.facets.res_format) {
          const format = {
            name: key,
            count: data.facets.res_format[key],
            selected: this.fileFormatQuery.length > 0 && this.fileFormatQuery.includes(key) ? true : false
          }
          this.fileFormatList.push(format);
        }
        this.categoryList = [];
        for (let key in data.facets.tags) {
          const category = {
            name: key,
            count: data.facets.tags[key],
            selected: Object.keys(this.query).length > 0 && this.query[key] ? true: false
          }                    
          this.categoryList.push(category);
        }
        this.categoryList.sort(x => x.selected ? -1 : 1);
        return this.datalist;
      },
      async dataSearch(query) {
        var categories = this.categoryList.filter((category) => category.name === query);
        const queryParam = Object.assign({}, this.$route.query);
        for (const category of categories) {
            if (category.selected == false) {
              this.query[query] = query;                  
            } else {
              delete this.query[query];
            }
        }
        if (Object.keys(this.query).length === 0) {
            delete queryParam.tags;
        } else {
          const tags = Object.keys(this.query).join(",");
          queryParam.tags = tags;
        }
        this.page = 1;    
        this.$router.push( { name: 'Data', params: { lang: this.$route.params.lang, id:this.page }, query: queryParam });
      },
      async filterFileFormat(format) {

        const queryParam = Object.assign({}, this.$route.query);
        
        if (!format.selected) {
          this.fileFormatQuery.push(format.name);
        } else {
          this.fileFormatQuery = this.fileFormatQuery.filter(fileFormat => fileFormat !== format.name);
        }
        if (this.fileFormatQuery.length === 0) {
            delete queryParam.formats;
        } else {
          const formats = this.fileFormatQuery.join(",");
          queryParam.formats = formats;
        }
        this.page = 1;
        this.$router.push( { name: 'Data', params: { lang: this.$route.params.lang, id: this.page }, query: queryParam });
      },
      getPage: function(pageNum) {        
        this.currentPage = pageNum - 1 || 0;
        this.page = this.currentPage + 1;
        this.$router.push( { name: 'Data', params: { lang: this.$route.params.lang, id:this.page }, query: this.$route.query });
      },
      checkTagsAndFormatParam: function() { 
        if (this.$route.query && this.$route.query.tags) {
            const tags = this.$route.query.tags.split(",");
            for (const tag of tags) {
              this.query[tag] = tag;
            }         
            this.updateTagClass(true);
        } else {
            this.query = {};
            this.updateTagClass(false);
        }
        if (this.$route.query && this.$route.query.formats) {
            const formats = this.$route.query.formats.split(",");
            for (const format of formats) {
              if (!this.fileFormatQuery.includes(format)) {
                this.fileFormatQuery.push(format);
              }
            }         
            this.updateFormatsList(true);
        } else {
            this.fileFormatQuery = [];
            this.updateFormatsList(false);
        }
      },
      checkQueryParam: function() {
        if (this.$route.query && this.$route.query.search){
          this.globalQuery = this.$route.query.search;
        } else {
          this.globalQuery = '';
        }
        this.checkTagsAndFormatParam();
        this.fetchData();
      },
      updateFormatsList: function(selected) {
        for (let format of this.fileFormatList) {
          if (this.fileFormatQuery.includes(format.name)) {
            format.selected = selected; 
          } else {
            format.selected = false;
          }
        }
      },
      updateTagClass: function(selected) {
        for (let tag of this.categoryList) {
          if (this.query[tag.name]) {
            tag.selected = selected; 
          } else {
            tag.selected = false;
          }                        
        }               
      },
      routeChange: function(){
        this.currentPage = this.$route.params.id - 1 || 0;
        this.page = this.currentPage + 1;
        this.checkQueryParam();
      }
    },
    async created() {
      this.routeChange();
    },
    watch: {
        $route() {
          this.routeChange();
        }
    }
}
</script>

<style scoped>

select{
  -webkit-appearance: listbox
}

.btn-gray, .btn-gray:active, .btn-gray:hover, .btn-gray:focus {
    background-color: #989898;
    border: 2px solid #989898;
    line-height: 1px;
}

.keywords button.selected {
  background-color: var(--lightgreen);
  border: 2px solid var(--lightgreen);
}

</style>