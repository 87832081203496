<template>
  <div class="container">
    <div class="login-form col-md-5 col-sm-8">
      <div class="tile">
        <div>
          <p class="text-center mt-20">{{ $t('login.title') }}</p>
          <form @submit.prevent="submit" novalidate="true">
            <div class="form-group" :class="{ error: v$.email.$error }">
              <!-- It was email before, not changing the name to avoid too many changes. -->
              <input v-model="email" type="text" name="email" class="form-control"
                placeholder="maxmustermann" @blur="v$.email.$touch" autocomplete="off"/>
              <div class="data-error font-normal-12 mt-4" v-if="v$.email.required.$invalid">
                <i class="fas fa-exclamation-circle"></i>
                <span class="ml-4">{{ $t('login.form.error.username') }}</span>
              </div>
              <!-- <div class="data-error font-normal-12 mt-4" v-if="v$.email.email.$invalid">
                <i class="fas fa-exclamation-circle"></i>
                <span class="ml-4">Please enter a valid email address</span>
              </div> -->
            </div>
            <div class="form-group" :class="{ error: v$.password.$error }">
              <input class="form-control" type="password" name="password" v-model="password"
              placeholder="******" @blur="v$.password.$touch" />
              <div class="data-error font-normal-12 mt-4"
                v-if="v$.password.required.$invalid">
                <i class="fas fa-exclamation-circle"></i>
                <span class="ml-4">{{ $t('login.form.error.pass_req') }}</span>
              </div>
            </div>
            <input type="submit" :value="$t('login.form.button')" class="btn btn-primary btn-orange" />
          </form>
          <div v-if="authError" class="text-center" :class="{ error: authError}" role="alert">
            <p class="data-error">{{ $t('login.form.error.invalid') }}</p>
          </div>
        </div>
      </div>
      <div class="login-links">
        <router-link :to="{ name: 'ForgotPassword', params: { lang: $route.params.lang } }">{{ $t('login.forgot_password') }}</router-link>
        <router-link :to="{ name: 'Registration', params: { lang: $route.params.lang } }">{{ $t('login.new_account') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AuthService from "../auth/service/AuthService";
import { mapActions } from "vuex";

export default {
  name: "Login",
  setup() {
    /* $lazy validations only runs once a field is dirty */
    return { v$: useVuelidate({ $lazy: true }) };
  },
  data() {
    return {
      email: null,
      password: null,
      authError: null
    };
  },
  validations() {
    return {
      email: { required },
      password: { required },
    };
  },
  mounted() {
    document.body.classList.add("login");
    document.getElementById("header-nav").classList.remove("nav-img");
  },
  destroyed() {
    document.body.classList.remove("login");
    document.getElementById("header-nav").classList.add("nav-img");
  },
  methods: {
    ...mapActions(["loginSession"]),
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      
      if (!isFormCorrect) return;
      
      // Submit form
      const data = await AuthService.loginUser({
        id: this.email,
        password: this.password,
      });
      
      // Show error message if there's an error or else manage session 
      if (data.result.errors) {
        this.authError = data.result.error_summary.auth;
        //console.log("error_summary.auth: ", data.result.error_summary.auth);
      } else {
        //console.log('Successful log in: ', data)
        this.loginSession({ 'token': data.result.token, 'username': data.result.name });
        this.$router.push('/data');
      }
    },
  },
};
</script>

<style>
.login {
  background-image: url("../../../public/img/green_background_full.png");
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 1900px) {
  .login {
    background-size: 100% 35%;
  }
}

.login-form {
  float: none;
  margin: auto;
  color: var(--grayfontlight);
}

.login-form .tile {
  width: 100%;
  height: auto;
  margin-top: 64px;
  box-shadow: 5px 5px 5px rgb(0 0 0 /10%);
}

.tile > div {
  padding: 24px 72px;
}

.login-form input {
  border: none;
  width: 100%;
  font-size: 16px;
}

.login-form input:focus {
  outline: none;
}

.login-form input {
  border-radius: 8px;
  margin-top: 30px;
  height: 48px;
  box-shadow: 6px 6px 12px 0px rgb(0 0 0 / 16%);
}

.login-form input {
  height: 48px;
}

.login-form input[type="submit"] {
  width: 55%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 16px;
}

.form-control:focus {
  box-shadow: none;
}

.login-form input[name="email"] {
  background: white url(../../../public/img/user.png) no-repeat 10px 12px;
  background-size: 24px;
  padding-left: 40px;
  -webkit-appearance: none;
}

.login-form input[name="password"] {
  background: white url(../../../public/img/password.png) no-repeat 12px 12px;
  background-size: 20px;
  padding-left: 40px;
  padding-top: 6px;
}

.login-form hr {
  margin-top: 0px;
  border-top: 3px solid var(--graybg);
}

/*.login-form p {
  font-family: verdana, sans-serif;
}*/

.login-links {
  display: flex;
  justify-content: space-between;
}

.login-links a {
  color: #4d4d4d;
  cursor: pointer;
  text-decoration: none;
}
</style>
