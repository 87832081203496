<template>
  <div class="gray-tile data-tile">
    <router-link :to="{name: 'Dataset', params: {'id': dataTileInfo.id, lang: $route.params.lang }}" class="roman-headers data-title">
      {{dataTileInfo.dataset_title}}</router-link>
    <p class="data-info">{{ dataTileInfo.org_title }} / 
      {{ dataTileInfo.resources[dataTileInfo.resources.length-1].last_modified | moment("MMM DD, YYYY") }}</p>
    <p class="data-text">{{ dataTileInfo.description }}</p>
    <div class="data-format text-center" v-for="fileFormat in dataTileInfo.fileFormats"
      :key="fileFormat" :class="fileFormat.toLowerCase()">{{ fileFormat }}</div>
  </div>
</template>

<script>
export default {
  name: "DataTile",
  props: {
    dataTileInfo: Object,
  },
};
</script>

<style scoped>
.data-tile .data-format {
  width: 48px;
  position: absolute;
  padding: 0px 6px;
  right: -8px;
  bottom: 16px;
  line-height: 1.2;
  color: white;
}

/* Adding css for 3 more elements to accomodate 4 file formats in a Data Tile */
.data-tile .data-format:nth-of-type(2) {
  bottom: 40px;
}

.data-tile .data-format:nth-of-type(3) {
  bottom: 64px;
}

.data-tile .data-format:nth-of-type(4) {
  bottom: 88px;
}

.data-format.csv {
  background-color: #0A7D41;
}

.data-format.txt {
  background-color: var(--grayfontdark);
}

.data-format.aasx {
  background-color: #fdc301;
}

.data-format.pdf {
  background-color: #CB1517;
}

.data-format.ipynb {
  background-color: #E26C2C;
}

.data-format.ppt {
  background-color: #D14424;
}


</style>