<template>
  <div class="container custom-container">

    <h1 class="text-center roman-headers">Show case</h1>

    <h2 class="text-center">{{showcaseInfo.title}}</h2>

    <div class="gray-text-container mt-20">

        <div class="row">
            <div class="col-md-6 col-sm-6">
                <p>Tags</p>
                <p v-for="tag in showcaseInfo.tags" :key="tag.id"><span class="darkgreen-color">{{tag.display_name}}</span></p>
            </div>
            <div v-if="showcaseInfo.author" class="col-md-6 col-sm-6">
                <p>Submitted by</p>
                <p>{{showcaseInfo.author}}</p>
            </div>
        </div>

        <div class="mt-32">
          <div v-if="showcaseInfo.datasets.length > 0">
            <div class="showcase-dataset-title">
                <img src="/img/datasets.png"><h3 class="roman-headers">Datasets in show case</h3>
            </div>
            <div v-for="dataset in showcaseInfo.datasets" :key="dataset.name">
              <router-link :to="{name: 'Dataset', params: {'id': dataset.name, lang: $route.params.lang }}" class="darkgreen-color text-underline">
                {{dataset.title}}</router-link>
            </div>
          </div>           

          <div class="mt-20">
              <img class="w-100" v-bind:src="showcaseInfo.image_display_url">
          </div>

          <div class="mt-32">
              <p class="showcase-date">{{ showcaseInfo.metadata_modified | moment("MMM DD, YYYY") }}</p>
              <p>{{showcaseInfo.notes}}</p>
          </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: 'Showcase',
  data() {
    return {
        showcaseInfo: {},
    }
  },
  methods: {
    async fetchData() {
      let searchUrl = '/api/showcase_show?id=';
      if (this.$route.params.id) {
        searchUrl = searchUrl + this.$route.params.id;
      }
      const res = await fetch(searchUrl)
      const data = await res.json();
      this.showcaseInfo = data.result;
    }
  },
  async created() {
    this.fetchData();
  }
}
</script>