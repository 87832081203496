export default {
    INVALID_EMAIL_MSG: 'Please enter valid email address.',
    INVALID_PASSWORD_MSG: 'Your password must be 8 characters or longer.',
    PASSWORD_MISMATCH_MSG: 'The passwords you entered do not match.',
    ALERT_SUCCESS_CLASS: 'alert alert-success',
    ALERT_DANGER_CLASS: 'alert alert-danger',
    REGISTRATION_SUCCESS_MSG_en: 'User registered successfully.',
    REGISTRATION_SUCCESS_MSG_de: 'Benutzer erfolgreich registriert.',
    CONTACT_US_SUCCESS_MSG_en: 'Message sent successfully.',
    CONTACT_US_SUCCESS_MSG_de: 'Nachricht erfolgreich gesendet.',
    FORGOT_PASSWORD_SUCCESS_MSG_en: 'A reset link has been emailed to you.',
    FORGOT_PASSWORD_SUCCESS_MSG_de: 'Sie haben einen Link zum Zurücksetzen per E-Mail erhalten.',
    RESET_SUCCESS_MSG_en: 'Your password has been reset.',
    RESET_SUCCESS_MSG_de: 'Ihr Passwort wurde zurückgesetzt.',
    UNKOWN_RESET_USER_MSG_en: 'User requested reset link for unknown user.',
    UNKOWN_RESET_USER_MSG_de: 'Benutzer hat Reset-Link für unbekannten Benutzer angefordert.',
    SOMETHING_WENT_WRONG_MSG_en: 'Something went wrong. Please try again later.',
    SOMETHING_WENT_WRONG_MSG_de: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    NO_DATA_FOUND: 'No datasets found.'
 }