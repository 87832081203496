<template>
    <div class="top-header">
        <div id="header-nav" class="navbar nav-img">
        <div class="container">
            <div class="inner_container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    </button>
                    <router-link :to="{ name: 'Home', params: { lang: $route.params.lang } }" class="logo pull-left">
                        <img class="img-responsive" src="/img/Logo_KI_Reallabor_NEU.svg">
                    </router-link>
                </div>
                <div class="collapse navbar-collapse" id="myNavbar">
                    <ul class="nav navbar-nav navbar-right header-nav-hover">
                        <li>
                            <router-link v-bind:class="{ 'active-tab': $route.meta.activeData}"  :to="{ name: 'Data', params: { lang: $route.params.lang, id:1 } }">{{ $t("header.nav.data") }}</router-link>
                        </li>
                        <li>
                            <router-link v-bind:class="{ 'active-tab': $route.meta.activeCuna}" :to="{ name: 'CunaProduction', params: { lang: $route.params.lang } }">{{ $t('header.nav.cuna_prod') }}</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'Showcases', params: { lang: $route.params.lang, id:1 } }" v-bind:class="{ 'active-tab': $route.meta.activeShowcases}">Showcases</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'AboutUs', params: { lang: $route.params.lang } }" v-bind:class="{ 'active-tab': $route.meta.activeAbout}">{{ $t('header.nav.about_us') }}</router-link>
                        </li>
                        <li v-if="$route.meta.activeCuna || $route.meta.activeAbout">
                            <router-link v-bind:class="{ 'active-tab': $route.meta.activeData}"  :to="{ name: 'Data', params: { lang: $route.params.lang, id:1 } }"><i class="fa fa-search"></i></router-link>
                        </li>
                        <li v-if="!$store.state.token">
                            <router-link :to="{ name: 'Registration', params: { lang: $route.params.lang } }" class="btn-primary btn-transparent nav-btn"> {{ $t('header.nav.register') }}</router-link>
                        </li>
                        <li v-if="!$store.state.token">
                            <router-link :to="{ name: 'Login', params: { lang: $route.params.lang } }" class="btn-primary btn-orange nav-btn login-btn">{{ $t('header.nav.log_in') }}</router-link>
                        </li>
                        <li v-if="$store.state.token">
                            <router-link :to="{ name: 'Logout', params: { lang: $route.params.lang } }" class="btn-primary btn-orange nav-btn login-btn">{{ $t('header.nav.log_out') }}</router-link>
                        </li>
                        <li>
                            <div class="display-flex d-flex-column">
                                <div class="lang-drop" :class="{ 'lang-drop-back': showLangDropdown }">
                                    <div v-on:click="showLangDropdown = !showLangDropdown"><img class="img-lang" :src="langImg"></div>
                                    <ul v-if="showLangDropdown" class="en-drop-down">
                                        <li v-on:click="onChange('en')" :class="{ 'selected': langauge == 'en' }">English</li>
                                        <li v-on:click="onChange('de')" :class="{ 'selected': langauge == 'de' }">Deutsch</li>
                                    </ul>
                                </div>
                            </div>                            
                        </li>
                    </ul>
                </div>
            </div>

            <h1 v-if="$route.meta.showHomeMessage" class="header-text mt-50 text-center" v-html="$t('header.title')"></h1>

            <!-- Search -->
            <section v-show="!$route.meta.hideSearch" class="similar-search mt-50 mb-50">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- searchbox -->
                        <div class="search-form form form-inline">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8 pad-none">
                        <span class="search-icon"><i class="fa fa-search"></i></span>
                            <input type="text" class="form-control input-lg" v-model="searchVal" v-bind:placeholder="$t('header.search_place')" @input="debounceSearch" v-on:keyup.enter="onSearch(searchVal)" required />
                        <span v-if="searchVal" class="close-icon" v-on:click="onSearch()"><i class="fa fa-times"></i></span>
                        </div>
                        <div class="col-sm-2"></div>
                        </div>
                        <!-- search box ended -->
                    </div>                   
                </div>
                <div class="row" v-if="searchList.length > 0">
                     <div class="col-sm-12">
                        <!-- searchbox -->
                        <div class="search-form form form-inline">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-8">
                        <div class="auto-search">
                            <div v-for="(data, i) in searchList" :key="i" class="search-result" v-on:click="onSearch(data.title)">
                                {{data.title}}
                            </div>
                            <infinite-loading v-if="searchList.length >= 5" @infinite="infiniteHandler" :distance="10">
                                <div slot="no-more">No more data</div>
                                <div slot="no-results">No results found</div>
                            </infinite-loading> 
                        </div>                                             
                        </div>
                        <div class="col-sm-2"></div>
                        </div>
                        <!-- search box ended -->
                    </div>
                </div>    
            </section>
            <!-- end of search -->
            </div>
        </div>
    </div>
</template>

<script>
import DataService from '../views/service/DataService'
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Header',
  components: {
    InfiniteLoading,
  },
  data() {
      return {
          classN: 'active-tab',
          langauge: 'en',
          langImg: '/img/English.svg',
          showLangDropdown: false,
          datasetType: 'dataset',
          searchVal: '',
          page: 1,
          searchList: []
      }
  },
  async created() {
      this.routeChange();
  },
  watch: {
    $route() {
        this.routeChange();
    }
  },
  methods: {
    onSearch: function(searchKey) {
        this.searchList = [];
        let route = 'Data'; 
        if (this.$route.name == 'Showcases') {
            route = 'Showcases'
        }               
        let queryParam = Object.assign({}, this.$route.query);       
        if (searchKey) {          
            queryParam.search = searchKey;
            this.$router.push( { name: route, params: { lang: this.$route.params.lang, id:1 }, query: queryParam });
        } else {
            this.searchVal = '';
            delete queryParam.search;
            let preQueryParam = Object.assign({}, this.$route.query);
            if (queryParam.search !== preQueryParam.search) {
                this.$router.push( { name: route, params: { lang: this.$route.params.lang, id:1 }, query: queryParam });
            }            
        }
    },
    infiniteHandler:  function($state) {
        this.datasetType = this.$route.name == 'Showcases' ? 'showcase' : 'dataset';
        DataService.fetchData(`/api/autocomplete?page=${this.page}&length=${this.searchList.length}&search=${this.searchVal}&type=${this.datasetType}`).then(data => {
            if (data.result.results.length > 0) {
                this.searchList = data.result.results;
                this.page = this.page + 1;
                if ($state) {
                    $state.loaded();
                }
            } else {
                if ($state) {
                    $state.complete();
                }                
            }                              
        });        
    },
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchVal = event.target.value;
        this.page = 1;
        if (this.searchVal.length > 0) {
            this.infiniteHandler();
        } else {
            this.searchList = [];
        }   
      }, 600)
    },
    routeChange: function() {
        this.searchList = [];        
        if (this.$route.query && this.$route.query.search) {
            this.searchVal = this.$route.query.search;
        } else {
            this.searchVal = '';
        }
        this.langauge = this.$route.params.lang ? this.$route.params.lang : 'en';
        const type = this.langauge == 'en' ? "English" : "Germany"
        this.langImg = `/img/${type}.svg`;
    },
    onChange: function(lang) {
        this.showLangDropdown = false;
        this.langauge = lang;
        const params = this.$route.params;
        params.lang = this.langauge;
        const type = this.langauge == 'en' ? "English" : "Germany";
        this.langImg = `/img/${type}.svg`;
        this.$router.push( { name: this.$route.name, params: params , query: this.$route.query});
    }
  }, 
}
</script>

<style scoped>
.auto-search {
    background: white;
    color: var(--grayfont);
    border-radius: 8px;
    max-height: 120px;
    overflow-y: auto;
    margin-right: 8px;
}
.col-sm-8 {
    padding-left: 0px;
    padding-right: 0px;
    background: white;
    border-radius: 12px;
}
.auto-search::-webkit-scrollbar {
  width: 8px;
}

.auto-search::-webkit-scrollbar-track {
  margin-top:8px;
  margin-bottom:8px;
}
 
.auto-search::-webkit-scrollbar-thumb {
  background: var(--darkgreen);
  border-radius: 4px;
}

.search-result {
    padding: 4px 48px;
    cursor: pointer;
}

.lang-drop {
    margin-top: 15px;
    width: 64px;
    border-radius: 10px;
    height: 35px;    
    text-align: center;
    padding: 4px;    
    cursor: pointer;
}

.lang-drop-back {
    background-color: #2D7C6A;
}

.img-lang {
    height: 24px;
}

.en-drop-down {
    background: #F2F2F2;
    height: auto;
    color: #2A2A2A;
    margin-top: 6px;
    margin-left: -64px;
    border-radius: 10px;
    width: 128px;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
}

.en-drop-down .selected {
    background: #B3B3B3;
}

ul {
  list-style-type: none;
}

.lang-drop select option {
    color: var(--grayfont);
}
</style>