
class AuthService {

    static async registerUser(user) {
        const res = await fetch("/api/registration", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        const data = await res.json();
        return data;
    }

    static async loginUser(user) {
        const res = await fetch("/api/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        const data = await res.json();
        return data
    }

    static async revokeUserToken(tokenObj) {
        await fetch("/api/revoke_token", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': tokenObj.token
            },
            body: JSON.stringify(tokenObj)
        })
    }

    static async getGroupsAndOrganizationList() {
        const res = await fetch("/api/group_organization_list");
        const data = await res.json();
        return data;
    }

    static async forgotPassword(email, url) {
        const res = await fetch(`/api/forgot_password?email=${email}&url=${url}`);
        const data = await res.json();
        return data;
    }

    static async resetPassword(user) {
        const res = await fetch("/api/reset_password", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        const data = await res.json();
        return data;
    }

    static async resetCheck(id, time) {
        const res = await fetch(`/api/reset_check?id=${id}&t=${time}`);
        const data = await res.json();
        return data;
    }    
}

export default AuthService;