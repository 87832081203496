<template>
    <div class="container roman-headers">
      <div class="data-form col-md-8 col-sm-8">
        <div class="tile">
          <div>
              <div v-if="alert.msg" :class="alert.class" role="alert">
                {{alert.msg}}
              </div>
              <div v-if="loading">
                <Loader/>              
              </div>
              <p class="text-center mt-20 mb-20">{{ $t('register.title') }}</p>
              <form novalidate>
                <div class="form-group" :class="{ 'error': v$.user.name.$error || error.invalid_name}">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.username') }}</span></div>
                    <input type="text" class="form-control font-normal-12" v-model="user.name" :placeholder="$t('register.form.username')" @blur="v$.user.name.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.user.name.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.username') }}</span></div>
                    <div class="data-error font-normal-12" v-if="error.invalid_name"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{error.invalid_name}}</span></div>
                </div>

                <div class="form-group">
                    <label class="font-normal-12">{{ $t('register.form.fullname') }}:</label>
                    <input type="text" class="form-control font-normal-12" v-model="user.fullname" placeholder="Max Mustermann">
                </div>

                <div class="form-group" :class="{ 'error': v$.user.email.$error || error.invalid_email}">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.email') }}:</span></div>
                    <input type="email" class="form-control font-normal-12" v-model="user.email" placeholder="max.mustermann@xy.com" @blur="v$.user.email.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.user.email.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.email_req') }}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.email.email.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.email_inv') }}</span></div>
                    <div class="data-error font-normal-12" v-if="error.invalid_email"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{error.invalid_email}}</span></div>
                </div>

                <div class="form-group">
                    <div class="display-flex data-error"><span class="font-normal-12 ml-4">{{ $t('register.form.organization') }}:</span></div>
                    <div class="position-relative">
                      <div class="custom-dropdown">                      
                        <select name="organization" id="organization" class="form-control font-normal-12" v-bind:class="{ 'capitalize': user.organization }" v-model="user.organization">
                          <option value="" disabled selected hidden>Chosse a organization</option>
                          <option v-for="organization in organizationList" :key="organization" :value="organization" class="capitalize">{{organization}}</option>
                          <option value="other">Others</option>
                        </select>
                      </div>
                    </div>                    
                </div>

                <div class="form-group" v-if="user.organization == 'other'" :class="{ 'error': v$.user.organizationName.$error}">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.organization_name') }}</span></div>
                    <input type="text" class="form-control font-normal-12" v-model="user.organizationName" :placeholder="$t('register.form.organization_name')" @blur="v$.user.organizationName.$touch" required>
                    <div class="data-error font-normal-12" v-if="v$.user.organizationName.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.organization_name') }}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.organizationName.minLength.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.organization_name_min') }}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.organizationName.maxLength.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.organization_name_max') }}</span></div>
                    <div class="data-error font-normal-12" v-if="!v$.user.organizationName.required.$invalid && !v$.user.organizationName.minLength.$invalid && !v$.user.organizationName.maxLength.$invalid && v$.user.organizationName.$error"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.organization_name_inv') }}</span></div>
                </div>                

                <div class="form-group" :class="{ 'error': v$.user.group.$error}">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.group') }}:</span></div>
                    <div class="position-relative">
                        <div class="custom-dropdown">                      
                        <select name="group" id="group" class="form-control font-normal-12" v-bind:class="{ 'capitalize': user.group }" v-model="user.group" @blur="v$.user.group.$touch">
                          <option value="" disabled selected hidden>Chosse a group</option>
                          <option v-for="group in groupList" :key="group" :value="group" class="capitalize">{{group}}</option>
                        </select>
                      </div>
                    </div>                 
                    <div class="data-error font-normal-12" v-if="v$.user.group.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.group') }}</span></div>
                </div>

                <div class="form-group" :class="{ 'error': v$.user.password.$error || error.invalid_password}">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.password') }}:</span></div>
                    <input type="password" class="form-control font-normal-12" v-model="user.password" placeholder="********" autocomplete="off" @blur="v$.user.password.$touch" required>
                    <div class="data-error font-normal-12" v-if="error.invalid_password"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{error.invalid_password}}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.password.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.pass_req') }}</span></div>
                    <div class="data-error font-normal-12" v-if="v$.user.password.minLength.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.pass_min') }}</span></div>
                </div>

                <div class="form-group" :class="{ 'error': v$.user.confirmPassword.$error || (user.confirmPassword && user.password != user.confirmPassword) }">
                    <div class="display-flex data-error"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.confirm') }}:</span></div>
                    <input type="password" class="form-control font-normal-12" v-model="user.confirmPassword" autocomplete="off" @blur="v$.user.confirmPassword.$touch" placeholder="********" required>
                    <div class="data-error font-normal-12" v-if="v$.user.confirmPassword.required.$invalid"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.confirm_pass_req') }}</span></div>
                    <div class="data-error font-normal-12" v-if="user.confirmPassword && user.password != user.confirmPassword"><i class="fas fa-exclamation-circle"></i><span class="ml-4">{{ $t('register.form.error.confirm_pass_inv') }}</span></div>
                </div>

                <!--<div class="form-group">
                    <label class="font-normal-12">Profile picture:</label>
                    <input id='file-upload' type='file' hidden/>
                    <div class="display-flex form-control file-upload cursor-pointer d-flex-align-center d-flex-justify-center" id="upload-btn"><span><i class="fas fa-cloud-upload-alt"></i></span><span class="font-normal-12 ml-8">Upload</span></div>
                </div>-->

                <div class="form-group">
                    <div class="display-flex"><span>*</span><span class="font-normal-12 ml-4">{{ $t('register.form.req_field') }}</span></div>
                </div>

                <div class="form-group float-right">
                    <input type="button" :value="$t('register.form.button')" class="btn btn-primary btn-orange font-normal-12 create-btn mb-20" v-on:click="validateForm">
                </div>
            </form>
          </div>
          </div>
      </div>
    </div>   
</template>

<script>
import AuthService from '../auth/service/AuthService'
import AppConstant from '../../config/app-constant'
import Loader from '@/components/Loader.vue'
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, minLength, maxLength, email } from "@vuelidate/validators";

export default {
  name: 'Registration',
  components: {
    Loader
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true }) };
  },
  data () {
    return {
      lang: 'en',
      user: {
        name:'',
        fullname:'',
        email: '',
        group: '',
        organization: '',
        organizationName: '',
        password: '',
        confirmPassword: ''
      },      
      error: {        
        invalid_email: '',
        invalid_password: '',
        invalidConfirmPassword: ''
      },
      formSubmitted: false,
      loading: false,
      organizationList:[],
      groupList:[],
      alert: {

      }
    }
  },
  validations: {
    user: {
      name: {
        required
      },
      group: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required
      },
      organizationName: {
        required: requiredIf ( function () {
          return this.user.organization == 'other';
        }),
        minLength: minLength(2),
        maxLength: maxLength(100),
        nameCheck: function () {
          return /^[a-z0-9_-]*$/.test(this.user.organizationName);        
        }
      }
    }
  },
  mounted () {
    document.body.classList.add('green-bg-img');
    document.getElementById('header-nav').classList.remove('nav-img');
  },
  destroyed () {
    document.body.classList.remove('green-bg-img');
    document.getElementById('header-nav').classList.add('nav-img');
  },
  async created() {
    this.lang = this.$route.params.lang ? this.$route.params.lang : 'en';
    this.fetchGroupsAndOrganizationList();
  },
  methods:{
    validateForm: function () {
      window.scrollTo(0,0);
      this.formSubmitted = true;
      this.error = {};
      this.alert = {};
      this.v$.$touch();
      if (this.v$.$invalid || (this.user.password != this.user.confirmPassword)) {
         return;
      } else {
        this.registerUser();
      }
    },
    hideErrorMsg: function (homeRedirect){
      setTimeout(()=>{
          this.$set(this.alert, 'class', '');
          this.$set(this.alert, 'msg', '');
          if (homeRedirect) {
            this.$router.push({ name: 'Home', params: { lang: this.$route.params.lang } });
          }
      },3000);
    },
    registerUser: async function () {
      this.loading = true; 
      const data = await AuthService.registerUser(this.user);
      this.loading = false; 
      if (data.success) {
        this.$set(this.alert, 'class', AppConstant.ALERT_SUCCESS_CLASS);
        this.$set(this.alert, 'msg', AppConstant[`REGISTRATION_SUCCESS_MSG_${this.lang}`]);
        this.formSubmitted = false;
        this.hideErrorMsg(true);
      } else {
        if (data.error) {
          for (let key in data.error) {
            let errorKey = 'invalid_' + key;
            this.$set(this.error, errorKey, data.error[key][0]);
          }
        } else {
          this.$set(this.alert, 'class', AppConstant.ALERT_DANGER_CLASS);
          this.$set(this.alert, 'msg', AppConstant[`SOMETHING_WENT_WRONG_MSG_${this.lang}`]);
          this.hideErrorMsg();
        }      
      }
    },
    fetchGroupsAndOrganizationList: async function (){
      const data = await AuthService.getGroupsAndOrganizationList();
      this.organizationList = data.organization_list;
      this.groupList = data.group_list;
    }
  }
}
</script>

<style>
.green-bg-img {
  background-image: url("../../../public/img/green_background_full.png");
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 1900px) {
  .login {
    background-size: 100% 35%;
  }
}

.data-form {
  float: none;
  margin: auto;
  color: var(--grayfontlight);
}

.green-bg-img .tile {
  width: 100%;
  height: auto;
  margin-top: 64px;
  box-shadow: 5px 5px 5px rgb(0 0 0 /10%);
  border-radius: 6px;
}

.tile > div {
  padding: 24px 88px;
}

.form-group {
  margin-bottom: 18px;
}

.green-bg-img .form-group .form-control {
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 4px rgb(0 0 0 /5%);
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.custom-dropdown > select {
  position: relative;
}

.custom-dropdown::before {  
  content: "\25BC";
  position: absolute;
  right: 20px;
  bottom: 5px;
  z-index: 99999;
}

input[type="file"] {
  display: none;
}

.file-upload {
  width: 88px;
}

.create-btn {
  border-radius: 4px;
  width: 124px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

@media (max-width: 768px) {
  .tile > div {
    padding: 24px 48px;
  }

  .custom-dropdown > option {
    width:50px;   
  }

  .custom-dropdown::before {  
    right: 64px
  }
}
</style>
